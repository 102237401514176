import Link from 'next/link';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightLong } from '@fortawesome/free-solid-svg-icons'
import LoadingSpinner from './LoadingSpinner';

const cssStyles =
  'rounded p-2 pl-6 pr-6 bg-primary flex flex-row justify-center items-center border border-primary peer';
const iconStyle = 'ml-1 w-6 h-4'

const TpButton = ({ isLink, action, text, className, showArrow, onClick, isSending, isDisabled }) =>
  isLink ? (
    <Link href={action} className={`${cssStyles} ${className}`}>
      <>
        {text}
        {showArrow && (
          <>
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faRightLong} size="lg" className={iconStyle} />
          </>
        )}
      </>
    </Link>
  ) : (
    <button className={`${cssStyles} ${className}`} onClick={onClick} disabled={isDisabled}>
      {isSending && <LoadingSpinner />}
      {text}
      {showArrow && (
        <>
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faRightLong} size="lg" className={iconStyle} />
        </>
      )}
    </button>
  );

TpButton.defaultProps = {
  isLink: true,
  className: '',
  showArrow: false
};

TpButton.propTypes = {
  isLink: PropTypes.bool,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  showArrow: PropTypes.bool
};

export default TpButton;
