import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useRouter } from 'next/router';

import TpButton from '../ui/Button';
import AppContainer from '../layouts/container';

import APP_IMAGES from '../../constants/appImages';
import { MENU_LIST } from '../../constants/menuList';

const Header = ({ activeLink }) => {
  const router = useRouter();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const goToLink = (link = '/') => {
    setShowMobileMenu(false);
    router.push(link);
  };

  const desktopHeader = (
    <div className="hidden 2lg:flex flex-row justify-between">
      {MENU_LIST.map(menu => (
        <Link
          key={menu.text}
          href={menu.url}
          className={`p-7 pb-10 text-xl text-gray-dark font-semibold hover:text-primary ${
            activeLink === menu.text
              ? 'text-primary border-b-4 border-primary'
              : ''
          }`}
        >
          {menu.text}
        </Link>
      ))}
      <TpButton
        className="bg-primary text-lg font-semibold mt-4 ml-10 mb-7 mr-4 text-white hover:text-primary hover:bg-white transition"
        text="Get a free estimate"
        action="/contact"
      />
    </div>
  );

  const mobileHeader = (
    <div className="flex 2lg:hidden flex-row w-100">
      <Image
        onClick={() => {
          setShowMobileMenu(true);
        }}
        src={APP_IMAGES.hamburgerImg}
        alt="hamburger"
        className="w-10"
      />
      <div
        className={`absolute z-50 top-0 left-0 w-full h-screen bg-gray-darker text-white p-5 overflow-hidden transition-all duration-700 ease-in-out ${
          showMobileMenu ? 'translate-y-0' : '-translate-y-full'
        }`}
      >
        <div className="flex flex-row justify-between items-center mt-2">
          <Image
            onClick={goToLink}
            src={APP_IMAGES.bitverxWhite}
            alt="Bitverx logo"
            width={160}
            height={20}
          />
          <Image
            onClick={() => {
              setShowMobileMenu(false);
            }}
            src={APP_IMAGES.closeIcon}
            alt="Close Icon"
            width={30}
          />
        </div>
        <ul className="flex flex-col mt-10 xxs:mt-24 h-screen">
          {MENU_LIST.map(menu => (
            <li
              key={menu.text}
              onClick={() => {
                goToLink(menu.url);
              }}
              className={`p-4 xs:pt-5 xxs:pb-5 w-full text-center rounded-md font-semibold text-xl	 ${
                activeLink === menu.text ? 'text-primary bg-white mb-3' : ''
              }`}
            >
              {menu.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <header className="order-1 pl-6 pr-6">
      <AppContainer className="mt-2 mb-4 sm:mt-5 sm:mb-6 flex flex-row justify-between border-b-0 sm:border-b border-gray-light">
        <div className="w-[200px] lg:w-[250px] h-20 flex items-center">
          <Image
            onClick={() => goToLink()}
            src={APP_IMAGES.logo}
            alt="Bitverx logo"
            className="cursor-pointer"
          />
        </div>
        {desktopHeader}
        {mobileHeader}
      </AppContainer>
    </header>
  );
};

Header.propsTypes = {
  activeLink: PropTypes.string
};

export default Header;
