import PropTypes from 'prop-types';

const AppContainer = ({ className, children }) => {
  return <div className={`container mx-auto ${className}`}>{children}</div>;
};

AppContainer.defaultProps = {
  className: ''
};

AppContainer.propTypes = {
  className: PropTypes.string
};

export default AppContainer;
