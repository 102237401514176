// Icons imports
import logo from '../public/images/bitverx-logo-1.svg';
import logoWhiteImg from '../public/images/tp-logo-white.svg';
import hamburgerImg from '../public/images/icons/hamburger.svg';
import closeIcon from '../public/images/icons/close-icon.svg';
import arrowRightIcon from '../public/images/icons/arrow-right.svg';
import arrowRightStraightIcon from '../public/images/icons/arrow-right-straight-white.svg';
import arrowFarwardLongIcon from '../public/images/icons/arrowFarwardLongIcon.svg';
import infoFillIcon from '../public/images/icons/info_fill.svg';
import checkedIcon from '../public/images/icons/checked-icon.svg';
import ideateIcon from '../public/images/home/ideate-icon.svg';
import designIcon from '../public/images/home/design-icon.svg';
import developIcon from '../public/images/home/develop-icon.svg';
import deployIcon from '../public/images/home/deploy-icon.svg';
import bitverxWhite from '../public/images/bitverx-white.svg';

// Home page images
import homeCodeImg from '../public/images/home/herosection.svg';
import circleImg from '../public/images/circle.svg';
import qouteImg from '../public/images/home/qoute.svg';
import ideaImg from '../public/images/home/idea-image.svg';
import assemble from '../public/images/home/assemble.svg';
import sprintPlanning from '../public/images/home/sprint-planning.svg';
import standupWeekly from '../public/images/home/standup-sweekly.svg';
import techarchitechture from '../public/images/home/tech-architecture.svg';
import codeReviews from '../public/images/home/code-reviews.svg';
import iterativeDelivery from '../public/images/home/Iterative-delivery.svg';
import businessImage from '../public/images/home/business-goal-image.svg';
import lineIndicatorUp from '../public/images/home/line-indicator-up.svg';
import lineIndicatorDown from '../public/images/home/line-indicator-down.svg';
import homeHeroImg from '../public/images/home/home-hero-img.png';

// Service
import roiArchitecture from '../public/images/service/roiArchitecture.svg';
import rapidApplicationAgile from '../public/images/service/rapidApplicationAgile.svg';
import microservicesArchitect from '../public/images/service/microservicesArchitect.svg';
import architectureGoals from '../public/images/service/architectureGoals.svg';
import backgroundLines from '../public/images/service/background-lines.svg';
import portFolioProductSliderImg from '../public/images/service/portFolioProductSliderImg.svg';
import feedBackImg from '../public/images/service/feedBack.svg';
import connectWithUsImg from '../public/images/service/connectWithUsImg.svg';
import positiveScaleImg from '../public/images/service/positiveScale.svg';
import negativeScaleImg from '../public/images/service/negativeScale.svg';
import arrowLeftIcon from '../public/images/service/arrowLeftIcon.svg';
import ux from '../public/images/service/ui-ux.svg';
import web from '../public/images/service/web.svg';
import mobile from '../public/images/service/mobile.svg';
import ecommerce from '../public/images/service/ecommerce.svg';
import devOps from '../public/images/service/devOps.svg';
import blockchain from '../public/images/service/blockchain.svg';
import servicesHeroImg from '../public/images/service/asset-service.svg';

import quoteOpen from '../public/images/quoteOpen.svg';
import quoteClose from '../public/images/quoteClose.svg';
import medal from '../public/images/about/medal.svg';
import integration from '../public/images/about/integration.svg';
import secretFile from '../public/images/about/secret-file.svg';
import timeManagement from '../public/images/about/time-management.svg';
import transparency from '../public/images/about/transparency.svg';
import adaptation from '../public/images/about/adaptation.svg';
import responsibility from '../public/images/about/responsibility.svg';
import coreValueBgImg from '../public/images/about/coreValueBg.svg';

// About
import aboutImg from '../public/images/about/about-hero.jpg';

// Career
import amigos from '../public/images/career/amigos.svg';
import arrowDown from '../public/images/career/arrowDown.svg';
import arrowUp from '../public/images/career/arrowUp.svg';
import upload from '../public/images/career/Upload.svg';

// Blog
import heroBlogImg from '../public/images/Blog/blogHeroImg.svg';
import vecterImg from '../public/images/Blog/vecterImg.svg';
import arrowFarward from '../public/images/Blog/arrow-farward.svg';

// Portfolio
import portfolioHeroImg from '../public/images/portfolio/portfolioHeroImg.svg';

// Technologies logos
import react from '../public/images/tech-logos/react-2.svg';
import angular from '../public/images/tech-logos/angular-icon-1.svg';
import vue from '../public/images/tech-logos/vue.svg';
import materialUI from '../public/images/tech-logos/material-ui.svg';
import typescript from '../public/images/tech-logos/typescript.svg';
import antDesign from '../public/images/tech-logos/ant-design.svg';
import gatsby from '../public/images/tech-logos/gatsby.svg';
import bulma from '../public/images/tech-logos/bulma.svg';
import bootstrap from '../public/images/tech-logos/bootstrap-4.svg';
import tailwindCSS from '../public/images/tech-logos/tailwind-css-2.svg';
import laravel from '../public/images/tech-logos/laravel-2.svg';
import html from '../public/images/tech-logos/html-1.svg';
import css from '../public/images/tech-logos/css-3.svg';
import javascript from '../public/images/tech-logos/javascript-1.svg';
import sass from '../public/images/tech-logos/sass-1.svg';
import nextjs from '../public/images/tech-logos/next-js.svg';

// Backend
import nodejs from '../public/images/tech-logos/nodejs-icon.svg';
import python from '../public/images/tech-logos/python-5.svg';
import php from '../public/images/tech-logos/php.svg';
import nestjs from '../public/images/tech-logos/nestjs.svg';
import django from '../public/images/tech-logos/django.svg';
import restApis from '../public/images/tech-logos/rest-api.svg';

// database
import firebase from '../public/images/tech-logos/firebase-1.svg';
import supabase from '../public/images/tech-logos/supabase.svg';

import google_cloud from '../public/images/tech-logos/google_cloud.svg';
import mongo_db from '../public/images/tech-logos/mongodb-icon-2.svg';
import my_sql from '../public/images/tech-logos/mysql-3.svg';
import heroku from '../public/images/tech-logos/heroku.svg';
import microsoft_sql_server from '../public/images/tech-logos/microsoft-sql-server-1.svg';
import el_sql from '../public/images/tech-logos/el-sql.svg';

// Mobile application
import android from '../public/images/tech-logos/android.svg';
import apple from '../public/images/tech-logos/apple.svg';
import react_native from '../public/images/tech-logos/react-native.svg';
import flutter from '../public/images/tech-logos/flutter.svg';
import flutter_flow from '../public/images/tech-logos/flutter-flow.svg';

// CMSs
import word_press_logo from '../public/images/tech-logos/wordpress.svg';
import strapi_logo from '../public/images/tech-logos/strapi-full-logo.svg';
import dato_logo from '../public/images/tech-logos/dato-cms.svg';
import shopify from '../public/images/tech-logos/shopify.svg';
import contentFul from '../public/images/tech-logos/Contentful.svg';

// UI/UX
import figma_logo from '../public/images/tech-logos/figma-logo.svg';
import photoshop_logo from '../public/images/tech-logos/adobe-photoshop.svg';
import illustrator_logo from '../public/images/tech-logos/Adobe-Illustrator-logo.svg';
import xd_logo from '../public/images/tech-logos/adobe-xd.svg';
import sketch_logo from '../public/images/tech-logos/sketch-2.svg';
import zeplin from '../public/images/tech-logos/zeplin.svg';

// clients logo
import instanConsult from '../public/images/clients/instant-consult.png';
import noYelling from '../public/images/clients/no-yelling.png';
import brandWatch from '../public/images/clients/brandwatch.png';
import richCommerce from '../public/images/clients/rich-commerce-1.png';

// Industries
import noYellingImg from '../public/images/industries/web/no-yelling.png';
import santraImg from '../public/images/industries/web/santra.png';
import ropoImg from '../public/images/industries/web/ropo.png';
import instanConsultImg from '../public/images/industries/web/instant-consult.png';
import aicImg from '../public/images/industries/web/AIC.png';
import instatuitionImg from '../public/images/industries/web/instatuition.png';
import richReturnImg from '../public/images/industries/web/rich-return.png';
import wtcTravelImg from '../public/images/industries/web/wtc-travel.png';
import fullCastImg from '../public/images/industries/web/ful-cast.png';

// Testimonals Imges
import tarik from '../public/images/testimonals/tarik.jpeg';
import sandra from '../public/images/testimonals/sandra.jpeg';
import dan from '../public/images/testimonals/dan.jpeg';
export const TESTIMONAL_IMAGES = {
  tarik,
  sandra,
  dan
};

export const CLIENTS_LOGOS = {
  noYelling,
  brandWatch,
  richCommerce,
  instanConsult
};

export const INDUSTRIES_IMG = {
  aicImg,
  ropoImg,
  santraImg,
  fullCastImg,
  noYellingImg,
  wtcTravelImg,
  richReturnImg,
  instatuitionImg,
  instanConsultImg
};

export const TECH_LOGOS = {
  vue,
  react,
  bulma,
  gatsby,
  angular,
  laravel,
  antDesign,
  bootstrap,
  materialUI,
  typescript,
  tailwindCSS,
  nodejs,
  python,
  php,
  nestjs,
  django,
  restApis,
  firebase,
  google_cloud,
  mongo_db,
  my_sql,
  heroku,
  microsoft_sql_server,
  el_sql,
  android,
  apple,
  react_native,
  flutter,
  flutter_flow,
  word_press_logo,
  strapi_logo,
  dato_logo,
  figma_logo,
  photoshop_logo,
  illustrator_logo,
  xd_logo,
  sketch_logo,
  html,
  css,
  javascript,
  sass,
  nextjs,
  supabase,
  zeplin,
  shopify,
  contentFul
};

// services
export const SERVICE_IMAGES = {
  ux,
  web,
  mobile,
  devOps,
  ecommerce,
  blockchain,
  servicesHeroImg,
  positiveScaleImg,
  negativeScaleImg,
  backgroundLines
};

const APP_IMAGES = {
  logo,
  logoWhiteImg,
  hamburgerImg,
  closeIcon,
  arrowRightIcon,
  arrowFarwardLongIcon,
  arrowRightStraightIcon,
  homeCodeImg,
  circleImg,
  qouteImg,
  ideaImg,
  businessImage,
  ideateIcon,
  designIcon,
  deployIcon,
  developIcon,
  lineIndicatorDown,
  lineIndicatorUp,
  quoteOpen,
  quoteClose,
  medal,
  integration,
  secretFile,
  timeManagement,
  transparency,
  adaptation,
  responsibility,
  roiArchitecture,
  rapidApplicationAgile,
  microservicesArchitect,
  architectureGoals,
  portFolioProductSliderImg,
  feedBackImg,
  connectWithUsImg,
  amigos,
  arrowDown,
  arrowUp,
  upload,
  heroBlogImg,
  vecterImg,
  arrowFarward,
  portfolioHeroImg,
  coreValueBgImg,
  positiveScaleImg,
  negativeScaleImg,
  arrowLeftIcon,
  infoFillIcon,
  checkedIcon,
  assemble,
  sprintPlanning,
  standupWeekly,
  iterativeDelivery,
  techarchitechture,
  codeReviews,
  aboutImg,
  bitverxWhite,
  homeHeroImg
};

export default APP_IMAGES;
