import PropTypes from 'prop-types';
import { DefaultSeo } from 'next-seo';
import defaultSeo from '../../next-seo.config';

import Header from '../common/Header';
import Footer from '../common/Footer';

const AppLayout = ({ children, seo, activeLink }) => {
  return (

    <div className="h-full flex flex-col">
      <DefaultSeo {...defaultSeo} {...seo} />
      <Header activeLink={activeLink} />
      <main className="order-2 grow">{children}</main>
      <Footer />
    </div>

  );
};

AppLayout.defaultProps = {
  className: '',
  seo: {},
  activeLink: ''
};

AppLayout.propTypes = {
  className: PropTypes.string,
  seo: PropTypes.object,
  activeLink: PropTypes.string
};

export default AppLayout;
