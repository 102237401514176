import Link from 'next/link';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import APP_IMAGES from '../../constants/appImages';
import { FOOTER_MENU } from '../../constants/menuList';

const Footer = () => {
  const desktop = (
    <div className="hidden sm:grid grid-cols-6 gap-3 container mx-auto">
      <div className="flex flex-col justify-between text-left">
        <p className="-rotate-180 writing-mode-vertical-rl">Lahore, Pakistan</p>
        <div className="flex flex-col">
          <Link href="https://www.facebook.com/bitverx" target='_blank' className="mb-4" aria-label='Facebook'>
            <FontAwesomeIcon icon={faSquareFacebook} size="sm" className='w-10 text-white' />
          </Link>
          <Link href="https://www.linkedin.com/company/bitverx/?viewAsMember=true" target='_blank' aria-label='LinkedIn'>
            <FontAwesomeIcon icon={faLinkedin} size="sm" className='w-10 text-white' />
          </Link>
        </div>
      </div>
      <div className="col-span-2 flex flex-col justify-between">
        <div>
          <h1>
            Connect
            <br />
            with us<span className="text-black">.</span>
          </h1>
          <Image
            className="hidden sm:block"
            src={APP_IMAGES.arrowRightIcon}
            alt="arrow"
            width={70}
            height={70}
          />
        </div>
        <p className="mt-40">
          Contact us <br />{' '}
          <Link
            href="mailto:contact@bitverx.io"
            className="text-white hover:text-black"
          >
            contact@bitverx.io
          </Link>
        </p>
      </div>
      <div className="flex flex-col">
        <h2 className="text-2xl mb-6">Menu</h2>
        <div className="flex flex-col">
          {FOOTER_MENU.map((menu, index) => (
            <Link
              key={index}
              href={menu.url}
              className="text-white mb-4 hover:text-black"
            >
              {menu.text}
            </Link>
          ))}
        </div>
      </div>
      <div className="flex flex-col">
        <h2 className="text-2xl mb-6">Office</h2>
        <Link href="/" className="text-white mb-4 hover:text-black">
          2nd Floor, Building No. 3 Block J-1,
          Sunflower Society
          Johar Town,
          <br /> Lahore, Pakistan
        </Link>
      </div>
      <p className="-rotate-180 writing-mode-vertical-lr flex justify-end">
        Connect
      </p>
    </div>
  );

  const mobile = (
    <div className="flex sm:hidden flex-col justify-center text-center">
      <h2 className="mb-10">
        Connect with us<span className="text-black">.</span>
      </h2>
      <div className="flex flex-col mb-10">
        <h3 className="mb-6 text-lg">Menu</h3>
        <div className="flex flex-row justify-center">
          {FOOTER_MENU.map((menu, index) => (
            <Link
              key={index}
              href={menu.url}
              className="text-white mr-2 text-xs xs:text-sm xs:mr-4 xs:text-md hover:text-black"
            >
              {menu.text}
            </Link>
          ))}
        </div>
      </div>
      <div className="flex flex-col mb-8">
        <h3 className="mb-6 text-lg">Office</h3>
        <Link
          href="/"
          className="text-white mb-4 text-xs xs:text-sm xs:text-md hover:text-black"
        >
          2nd Floor, Building No. 3 Block J-1,
          Sunflower Society
          Johar Town,
          <br /> Lahore, Pakistan
        </Link>
      </div>
      <p className="mb-8 text-xs xs:text-sm">
        Contact us <br />{' '}
        <Link
          href="mailto:contact@bitverx.io"
          className="text-white hover:text-black hover:underline"
        >
          contact@bitverx.io
        </Link>
      </p>
      <div className="flex flex-row justify-center">
        <Link href="https://www.facebook.com/bitverx" className="mb-4 mr-6" aria-label='Facebook'>
          <FontAwesomeIcon icon={faSquareFacebook} size="sm" className='w-10 text-white' />
        </Link>
        <Link href="https://www.linkedin.com/company/bitverx/?viewAsMember=true" aria-label='LinkedIn'>
          <FontAwesomeIcon icon={faLinkedin} size="sm" className='w-10 text-white' />
        </Link>
      </div>
    </div>
  );

  return (
    <footer className="order-3 bg-primary text-white p-4 sm:p-8 pt-12 pb-20 sm:pt-24 sm:pb-24">
      {desktop}
      {mobile}
    </footer>
  );
};

export default Footer;
