const config = {
  defaultTitle: 'Bitverx-Expert Custom Software, Web, Mobile App, SaaS, AI & Cloud Solutions',
  description: 'Bitverx builds elite software teams for web and mobile app development, SaaS, AI, and cloud solutions, empowering startups and enterprises to innovate.',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://bitverx.io/',
    site_name: 'Bitverx-Expert Custom Software, Web, Mobile App, SaaS, AI & Cloud Solutions'
  }
};

export default config;
