import React from 'react';

const LoadingSpinner = ({ className, text }) => {
    return (
        <div className={`flex items-center justify-center mr-3 ${className}`}>
            <div className="w-5 h-5 border-2 border-t-transparent border-solid rounded-full animate-spin border-blue-500"></div>
            {text && <span className="ml-3">{text}</span>}
        </div>
    );
};

export default LoadingSpinner;